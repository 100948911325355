import { useState, useEffect } from 'react';
import { createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Copyright from 'components/Copyright/Copyright';
import colors from 'assets/theme/base/colors';
import { Paper, CircularProgress } from '@mui/material';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { API_URL } from 'constants/api';
import authHeader from 'components/Authentication/DataService';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Pagination from '@mui/material/Pagination';
import AzureSignInsList from './AzureSignInsList';
import AzureCloudSelect from '../AzureCloudSelect';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Button from '@mui/material/Button';


var newDate = new Date()

const defaultTheme = createTheme({
    palette: {...colors},
});

export default function AzureSignIns() {
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false)
    const [signIns, setSignIns] = useState([]);
    const [numPages, setNumPages] = useState();
    const [hasNext, setHasNext] = useState();
    const [hasPrev, setHasPrev] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [prevPage, setPrevPage] = useState(0);
    const [nextPage, setNextPage] = useState(2);
    const [urlQuery, setUrlQuery] = useState();
    const nav = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams()
    const page = searchParams.get("page")
    const [cloudKeys, setCloudkeys] = useState([]);
    const [fromTime, setFromTime] = useState(dayjs(newDate.setDate(newDate.getDate() -7)));
    const [toTime, setToTime] = useState(dayjs(new Date()));
    const [open, setOpen] = useState(false);
    const filters = [];

    searchParams.forEach((value, key) => {
        if ( key === "filter" ) {
            filters.push(value)
        }
    })

    function redirectOnLogout() {
        localStorage.removeItem("token");
        nav("/sign-in")
    }
    
    const onPageSubmit = (event, value) => {
        setLoading(true)
        setCurrentPage(value)
        searchParams.append("page", value)
        setSearchParams(searchParams)
        setLoading(false)
    }
       
    const updateDate = (() => {
        setButtonLoading(true)
        if ( fromTime.unix() > toTime.unix()) {
            setError(true)
            setErrorMessage("From date must be less than To date")
        } else {
            setSearchParams({from: fromTime.unix(), to: toTime.unix()})
        }
        setButtonLoading(false)
    })

      
    var cloudKey = localStorage.getItem("cloud_key_azure")
    if ( cloudKey === null ) {
      fetch(API_URL + 'subs/cloud-keys/default', {headers: authHeader() }).then( res => res.json())
      .then( (res) => {
        localStorage.setItem("cloud_key_azure", res.id)
        // localStorage.setItem("cloud_key_azure", )
      })
      .catch( err => {
        console.log(err)
      })
    }

    useEffect(() => {
        Promise.all([
          fetch(API_URL+ 'ping', {headers: authHeader() }).then( res => { 
            if ( res.status === 401 ) { 
                redirectOnLogout() 
            }
          }),
          fetch(API_URL+'azure/sign-ins?'+searchParams, {headers: authHeader() }).then(res => res.json()),
          fetch(API_URL + 'subs/cloud-keys?service-short=AZR', {headers: authHeader() }).then( res => res.json() ),
        ])
        .then( (res) => {
          setSignIns(res[1]);
          setNumPages(res[1]["num_pages"])
          setHasNext(res[1]["has_next"])
          setHasPrev(res[1]["has_previous"])
          setCloudkeys(res[2])
          setLoading(false)
        })
        .catch( err => {
          setLoading(false)
          console.log("Error encountered in Promise: ", err)
        })
        

      }, [searchParams]);


    
    const title = "Azure AD Sign Ins"
    document.title = title;
    function formatClock(date) {
        const clock = new Date(date)
        return clock.toLocaleDateString()
    }

    function ShowPagination() {
        return (
            <Pagination onChange={onPageSubmit} page={currentPage}  count={numPages} variant="outlined" color="primary" siblingCount={2}  hidePrevButton hideNextButton  />
        )
    }

    if ( loading === true ) {
        return (
            <Typography variant="h4"><CircularProgress color="dark" size="1rem" /></Typography>
        )
    } else {
        return (
                <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                }}
                >
                    <Grid sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={9} lg={9}>
                                <Breadcrumbs aria-label="breadcrumb" >
                                    <Link to="/azure" ><Typography color="darkgreen.main">Azure</Typography></Link>
                                    <Typography color="text.primary">Sign Ins</Typography>
                                </Breadcrumbs>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <AzureCloudSelect keys={cloudKeys} selected={cloudKey}  />
                            </Grid>
                            <Grid item xs={12} md={9} lg={9}>
                                <AzureSignInsList signins={signIns.data} title={"Sign Ins"} filters={filters}  />
                                <Grid mt={5} >
                                    {numPages > 0 ? <ShowPagination /> : "" }
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={3} lg={3} >
                                <Grid container spacing={3}>

                                    <Grid item xs={12} >
                                        <Paper
                                            sx={{
                                                p: 2,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                height: "100%",
                                            }}
                                        >
                                            <Typography variant="caption" sx={{ mb: 2 }}>Graph date range</Typography>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker label="Select start date" disableFuture onChange={setFromTime} />
                                                <DatePicker label="Select end date" disableFuture onChange={setToTime} sx={{ mt: 2 }} />
                                            </LocalizationProvider>
                                            {buttonLoading ? <Button sx={{ mt: 2}} variant="contained" size="small" >Loading</Button> : <Button sx={{ mt: 2}} variant="outlined" size="small" onClick={updateDate}>Update</Button> }
                                        </Paper>
                                    </Grid>
                                
                                </Grid>
                            </Grid>
                        </Grid>
                        <Copyright sx={{ pt: 4 }} />
                    </Grid>
                </Box>

        );
    }
}
